export default [
  {
    path: '/system',
    children: [
      {
        path: '',
        name: 'system-index',
        meta: { title: 'Daftar Layanan' },
        component: () => import('@/views/system/index-page.vue'),
      },
    ],
  },
  {
    path: '/room',
    children: [
      {
        path: '',
        name: 'room-index',
        meta: { title: 'Daftar Ruangan' },
        component: () => import('@/views/room/index-page.vue'),
      },
    ],
  },
  {
    path: '/contract-template',
    children: [
      {
        path: '',
        name: 'contract-index',
        meta: { title: 'Daftar Template Kontrak' },
        component: () => import('@/views/template-contract/index-page.vue'),
      },
    ],
  },
  {
    path: '/project-stage',
    children: [
      {
        path: '',
        name: 'project-stage-index',
        meta: { title: 'Daftar Tingkat Proyek' },
        component: () => import('@/views/project-stage/index-page.vue'),
      },
    ],
  },
]