export default [
  {
    path: '/access',
    children: [
      {
        path: '',
        name: 'role-permission-index',
        meta: { title: 'Hak Akses' },
        component: () => import('@/views/role-permission/index-page.vue'),
      },
    ],
  },
  {
    path: '/profile',
    children: [
      {
        path: '',
        name: 'profile',
        meta: { title: 'Akun Saya' },
        component: () => import('@/views/profile/index-page/main-page.vue'),
      },
      {
        path: 'email',
        name: 'profile-email',
        meta: { title: 'Akun Saya' },
        component: () => import('@/views/profile/change-email-page.vue'),
      },
      {
        path: 'password',
        name: 'profile-password',
        meta: { title: 'Akun Saya' },
        component: () => import('@/views/profile/change-password-page.vue'),
      },
    ],
  },
  {
    path: '/setting',
    children: [
      {
        path: '',
        name: 'setting-index',
        meta: { title: 'Daftar Pengaturan' },
        component: () => import('@/views/setting/index-page.vue'),
      },
    ],
  },
]