export default [
  {
    path: '/user',
    children: [
      {
        path: '',
        name: 'user-index',
        meta: { title: 'Pengguna', hasFilter: true },
        component: () => import('@/views/user/index-page/main-page.vue'),
      },
      {
        path: ':user_id/task-list',
        name: 'user-task-list',
        meta: { title: 'Daftar Tugas Pengguna', backButton: true, hasFilter: true },
        component: () => import('@/views/user/task-list/index-page.vue'),
      },
    ],
  },
  {
    path: '/internal-task',
    children: [
      {
        path: '',
        name: 'internal-task-index',
        meta: { title: 'Daftar Tugas Internal', hasFilter: true },
        component: () => import('@/views/internal-task/index-page/main-page.vue'),
      },
      {
        path: ':internal_task_id',
        name: 'internal-task-view',
        meta: { title: 'Detail Tugas Internal' },
        component: () => import('@/views/internal-task/view-page/main-page.vue'),
      },
    ],
  },
]