export default [
  {
    path: '/project',
    children: [
      {
        path: '',
        name: 'project-index',
        meta: { title: 'Daftar Proyek', hasFilter: true },
        component: () => import('@/views/project/index-page/main-page.vue'),
      },
      {
        path: ':project_id',
        children: [
          {
            path: 'view',
            name: 'project-view',
            meta: { title: 'Rincian Proyek', hasFilter: false, backButton: true },
            component: () => import('@/views/project/view-page/main-page.vue'),
          },
          {
            path: 'record-of-transfer',
            name: 'project-rot-form',
            meta: { title: 'Buat Berita Acara', backButton: true, manualBack: true },
            component: () => import('@/views/report/record-of-transfer/main-page.vue'),
          },
          {
            path: 'review',
            name: 'project-review-form',
            meta: { title: 'Buat Form Review', backButton: true },
            component: () => import('@/views/project/review-form-page/index-page.vue'),
          },
          {
            path: 'task',
            children: [
              {
                path: ':task_id/view',
                name: 'task-view',
                meta: { title: 'Detail Tugas', backButton: true },
                component: () => import('@/views/task/view-page/main-page.vue'),
              },
            ],
          },
        ]
      },
    ],
  },
  {
    path: '/draft',
    children: [
      {
        path: '',
        name: 'draft-index',
        meta: { title: 'Daftar Draft Tugas' },
        component: () => import('@/views/draft/index-page.vue'),
      },
    ],
  },
  {
    path: '/task',
    children: [
      {
        path: '',
        name: 'task-index',
        meta: { title: 'Daftar Tugas', hasFilter: true },
        component: () => import('@/views/task/index-page/main-page.vue'),
      },
      {
        path: 'transfer',
        name: 'task-transfer',
        meta: { title: 'Transfer Tugas', backButton: true },
        component: () => import('@/views/task/transfer-page/main-page.vue'),
      },
    ],
  },
  {
    path: '/record-of-transfer',
    children: [
      {
        path: '',
        name: 'rot-index',
        meta: { title: 'Daftar Berita Acara' },
        component: () => import('@/views/record-of-transfer/index-page/main-page.vue'),
      },
    ],
  },
]